/* 
  新增 GenYoGothic 字型的原因可以參考這張單：https://kadokado.atlassian.net/browse/KAD-3225
  字體載點：https://github.com/ButTaiwan/genyog-font
 */
@font-face {
  font-family: 'GenYoGothic';
  src: url('/static/fonts/GenYoGothic/GenYoGothic-L.ttc');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'GenYoGothic';
  src: url('/static/fonts/GenYoGothic/GenYoGothic-R.ttc');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'GenYoGothic';
  src: url('/static/fonts/GenYoGothic/GenYoGothic-M.ttc');
  font-weight: 500;
  font-style: normal;
}

* {
  transition-property: background-color;
  transition-timing-function: ease-out;
  transition-duration: 0.25s;
}

button,
a {
  cursor: pointer;
}

.ant-picker-month-panel td {
  vertical-align: middle;
}
